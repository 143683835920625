<template>
  <div>
    <template>
        <div class="fieldProgressBar">
            <h3 class="title font-poppins fs-20 fw-800 text-primary-one">{{ module.title }}</h3>
            <div class="progress_bar relative h-1 bg-">
                <span class="_progress absolute h-full" :style="{left:0, width:'100%', background: '#2B80AD'}">
                  <span class="progress_text absolute font-poppins fs-20 fw-600 text-secondary-one">{{ getParameterValue(module, filterParameter.id, 'module_properties') }} {{ filterParameter.suffix }}</span>
                </span>
            </div>
        </div>
    </template>
    <ProgressBar
        v-for="(collection, index) in localInstance.getCollections(module, instance.progressView.groupOrAtomic)"
        :key="index"
        :title="`${collection.index_code} ${collection.title}`"
        :amount="`${getParameterValue(collection, filterParameter.id)} ${filterParameter.suffix}`"
        :left="`${getLeftMargin(index)}%`"
        :background="getBackground(collection, module)"
        :width="`${getWidth(collection, filterParameter.id)}%`" />
  </div>
</template>

<script>
import ProgressBar from './ProgressBar.vue'
import Definition from "@/store/services/traits/definition";
import ProgressSliceController from "@/components/dashboard/progressView/progressSliceController";
import {getParameterValue} from "@/store/services/traits/parameter";
import Collection from "@/store/services/traits/collection";
export default {
  components: {ProgressBar},
  props: {
    module: Object,
    filterParameter: Object,
    filterDefinition: Object,
    instance: Object
  },
  data() {
    return {
      definition: new Definition,
      localInstance: null,
      collectionInstance: null,
      fieldSummation: 0,
      fieldValueArray: []
    }
  },
  watch: {
    'instance.progressView.isChild': function (val) {
      this.getParameterSummation();
    },
    filterParameter(val) {
      this.getParameterSummation();
    }
  },
  methods: {
    getParameterValue(collection, parameterId, key='properties') {
      return getParameterValue(collection, { id: parameterId }, key).toFixed(2);
    },
    getParameterSummation() {
      this.fieldValueArray = [];
      let collections = this.localInstance.getCollections(this.module, this.instance.progressView.groupOrAtomic);
      let summation = 0;
      let parameterId = this.filterParameter.id;
      collections.forEach(item => {
        let paramValue = parseFloat(this.getParameterValue(item, parameterId));
        summation += paramValue;
        this.fieldValueArray.push({
          id: item.id,
          parameter_id: parameterId,
          sample_value: paramValue,
          summation: summation
        });
      });
      this.fieldSummation = summation;
    },
    getWidth(collection, parameterId) {
      let value = getParameterValue(collection, { id: parameterId }).toFixed(2);
      return (value * 100) / this.fieldSummation;
    },
    getLeftMargin(index) {
      if(index > 0 && this.fieldValueArray && this.fieldValueArray[index - 1]) {
        let item = this.fieldValueArray[index - 1];
        return (item.summation * 100) / this.fieldSummation;
      }
      return 0;
    },
    getBackground(collection, module) {
      return this.collectionInstance.getBackground(collection, module);
    }
  },
  created() {
    this.localInstance = new ProgressSliceController(this.module);
    this.collectionInstance = new Collection(this.module)
    this.getParameterSummation();
  }
}
</script>



<style scoped>
  .fieldProgressBar{
    padding-bottom: 44px;
    margin-bottom: 12px;
    border-bottom: 1px solid #c9c9c9;
  }
  .title{
    margin-bottom: 44px;
  }
  .progress_bar{
    background-color: #D9D9D9;
    height: 13px;
    border-radius: 2px;
  }
  ._progress{
    border-radius: 2px;
  }
  .progress_text{
    top: -34px;
    /* transform: translateX(-50%); */
    left: 42%;
    white-space: nowrap;
  }
</style>
