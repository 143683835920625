<template>
    <div class="flex justify-start gap-2 fs-14 font-poppins text-primary-one fw-500" >
        <template v-for="(type, key) in items">
            <slot v-bind="type">
                <NavLink
                    :key="key"
                    :label="type.label"
                    :isActive="type.active"
                    @onClick="$emit('onClick', type)"
                />
            </slot>
        </template>
    </div>
</template>

<script>
import NavLink from './NavLink'
export default {
    components: {
        NavLink
    },
    props: {
        items: {
            type: Array,
            default: () => []
        }
    }
}
</script>