<template>
  <div>
    <div class="relative top_part">
        <GearIcon class="absolute w-5 h-5 gear_icon cursor-pointer" @click.native="$emit('openPopup')" />
        <div v-for="(definitionItem, index) in getDefinitionItems" :key="index">
          
          <div v-if="definitionItem.type === 1">
            <div class="content">
              <h2 class="fs-14 capitalize text-primary-one fw-700">{{ definitionItem.title }}</h2>
              <h2 class="fs-30 capitalize text-primary-one fw-600 mt-2">{{ getOverallSummary() }}%</h2>
              <h2 class="fs-14 _custom_gray_color fw-400 overflow-y-hidden overall-definition" :title="getProgressDefinitionDescription(definitionItem)">
                {{ getProgressDefinitionDescription(definitionItem) }}
              </h2>
            </div>
            <div class="progress_circle">
                <CircleProgress :size="140" :color="getProgressColor(definitionItem)" :percent="getOverallSummary()">
                    <component
                        class="cursor-pointer"
                        :color="getProgressColor(definitionItem)"
                        :is="getProgressIcon(definitionItem).replaceAll(/-/ig, '_')">
                    </component>
                </CircleProgress>
            </div>
          </div>



          <div v-if="definitionItem.type === 2 || definitionItem.type === 3" class="pl-10 pr-10">
            <!-- <div>
                <h2 class="fs-18 fw-800 text-primary-one mb-2">PROGRESS SENTIMENT</h2>
                <div class="flex gap-9">
                    <dashboard_progress color="#2BAD7E" />
                    <div>
                        <h2 class="fs-30 fw-700 text-primary-one">60% <span class="fs-14 fw-500 ">Actual</span></h2>
                        <svg width="193" height="6" viewBox="0 0 193 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="100%" height="6" fill="#E2E2E2"/>
                            <rect width="75%" height="6" fill="#2B80AD"/>
                        </svg>
                    </div>
                </div>
                <div>
                    <h2 class="fs-22 fw-800 text-primary-one">Going in right direction</h2>
                    <p class="fs-18 fw-400 text-primary-one">This is a custom definition that can be modified hitting the configuration icon.</p>
                </div>
            </div> -->
            <StatusBox :title="definitionItem.title"
                        :percantage="getAverageProjectProgressByDefinition(definitionItem)"
                        emoji="smile"
                        :subtitle="getProgressDefinitionDescription(definitionItem)"
                        :color="getProgressColor(definitionItem)"
                        :component="getProgressIcon(definitionItem).replaceAll(/-/ig, '_')"
                        :progress="{color: getProgressColor(definitionItem), value: getAverageProjectProgressByDefinition(definitionItem)}" />
          </div>

        </div>
    </div>
    <hr class="hr my-5" />
    <ProjectPanel :instance="instance" />
  </div>
</template>

<script>
    import StatusBox from "./StatusBox";
    import CircleProgress from "../CircleProgress";
    import circleChart from "../icons/circleChart";
    import overall_dashboard_check from '../icons/overall_dashboard_check';
    import overall_dashboard_progress from '../icons/overall_dashboard_progress';
    import overall_dashboard_pause from '../icons/overall_dashboard_pause';
    import overall_dashboard_close from '../icons/overall_dashboard_close';
    import overall_dashboard_happy from '../icons/overall_dashboard_happy';
    import overall_dashboard_unhappy from '../icons/overall_dashboard_unhappy';

    import dashboard_check from '@/components/dashboard/icons/lg/dashboard_check';
    import dashboard_progress from '@/components/dashboard/icons/lg/dashboard_progress';
    import dashboard_pause from '@/components/dashboard/icons/lg/dashboard_pause';
    import dashboard_close from '@/components/dashboard/icons/lg/dashboard_close';
    

    import ProjectPanel from "@/components/dashboard/projects/ProjectPanel";

  export default {
    data() {
        return {
            percent: 90,
            workspace: this.instance.workspace.workspace
        }
    },
    components: {
        CircleProgress,
        StatusBox,
        circleChart,
        overall_dashboard_check,
        overall_dashboard_progress,
        overall_dashboard_pause,
        overall_dashboard_close,
        overall_dashboard_happy,
        overall_dashboard_unhappy,
        ProjectPanel,
        dashboard_check,
        dashboard_progress,
        dashboard_pause,
        dashboard_close
    },
    props: {
        instance: Object
    },
    computed: {
        circle() {
          return ((this.percent / 100) * 100 * Math.PI) + ',9999';
        },
        getWorkspace() {
          return this.instance.workspace.workspace;
        },
        getDefinition() {
          return this.getWorkspace?.definition_group;
        },
        getDefinitionItems() {
          let definitionGroup = this.getDefinition;
          return definitionGroup ? definitionGroup.items : [];
        }
      },
      methods: {
        getAverageProjectProgressByDefinition(definitionItem) {
          return this.instance.summary.getAverageProjectProgressByDefinition(this.instance.getDisplayableProjects(), definitionItem);
        },
        getProgressColor(definitionItem) {
          let value = definitionItem.type === 2 ? this.getAverageProjectProgressByDefinition(definitionItem) : this.getOverallSummary();
          return this.instance.summary.getAvgProgressColor(value, definitionItem);
        },
        getProgressDefinitionDescription(definitionItem) {
          let value = definitionItem.type === 2 ? this.getAverageProjectProgressByDefinition(definitionItem) : this.getOverallSummary();
          return this.instance.summary.getAvgProgressDefinitionDescription(value, definitionItem);
        },
        getOverallSummary() {
          let summation = 0;
          this.getDefinitionItems.forEach(data => {
            if(data.type === 2 || data.type === 3) {
              summation += parseFloat(this.getAverageProjectProgressByDefinition(data));
            }
          });
          return summation > 0 ? (summation / 2).toFixed(2) :0;
        },
        getProgressIcon(definitionItem) {
          let value = definitionItem.type !== 1 ? this.getAverageProjectProgressByDefinition(definitionItem) : this.getOverallSummary();
          return 'overall_' + this.instance.summary.getAvgProgressIcon(value, definitionItem);
        },
        updateWorkspace() {
          let data = {
            id: this.workspace.id,
            name: this.workspace.name,
            personal_statement: this.workspace.personal_statement
          };
          this.instance.summary.updateWorkspace(data);
        }
      }
  }
</script>

<style scoped>
  .indication {
    height: 110px;
  }
  .overall-definition {
    height: 60px;
  }
  .hr{
    border-bottom: 1px solid #C9C9C9;
  }
</style>
