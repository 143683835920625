<template>
    <div class="py-5 px-7">
        <h2 class="fs-20 fw-600 mb-3 pb-2 border-b border-secondary-five project_name cursor-pointer truncate"
            :title="!canRead(project.cans) ? 'No permission to access it. Ask administrator about permission' : ''"
            @click="canEdit(project.cans)&&$emit('onClick')">
          {{ project.name }}
        </h2>

        <div class="flex gap-8 mb-3">
          <template v-for="(definitionItem, index) in getDefinitionItems">
            <div :key="index+'-'+Math.random() * 10000" v-if="definitionItem.type === 2 || definitionItem.type === 3" class="w-1/2">
              <h2 class="fs-14 fw-400 text-color-616161 uppercase truncate" :title="definitionItem.title">{{ definitionItem.title }}</h2>
              <h2 class="fs-16 fw-700 text-primary-one h-25px truncate" :title="getProgressDefinitionTitle(definitionItem)">
                {{ getProgressDefinitionTitle(definitionItem) }}
              </h2>
              <div class="mt-3 flex gap-3 items-center select-none">
                <!-- icon start -->
                <div class="ProjectBoxIcon" :title="!canEdit(project.cans) ? 'No permission to edit. Ask administrator about permission' : ''">
                  <component class="cursor-pointer -ml-2"
                    :color="getProgressColor(definitionItem)"
                    @click.native="canEdit(project.cans)&&toggleProgress(definitionItem)"
                    :is="getProgressIcon(definitionItem).replaceAll(/-/ig, '_')"></component>
                </div>
                <!-- icon end -->
                <h2 class="fs-24 fw-700 text-primary-one">
                  {{ getProjectProgress(definitionItem) }}%
                </h2>
              </div>
            </div>
          </template>
        </div>

        <div v-for="(definitionItem, index) in getDefinitionItems" :key="index+'-'+Math.random() * 10000">
          <svg v-if="definitionItem.type === 3" width="100%" height="8" viewBox="0 0 388 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100%" height="8" fill="#EDEDED"/>
            <rect :width="getProjectIndicationProgress() + '%'" height="8" :fill="getIndicationProgressColor(definitionItem)"/>
          </svg>
        </div>

    </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canRead, canDelete, canCreate, canEdit } = usePermission()
</script>

<script>
    import statusIcon from '../components/statusIcon.vue'
    import dashboard_check from '../icons/lg/dashboard_check';
    import dashboard_progress from '../icons/lg/dashboard_progress';
    import dashboard_pause from '../icons/lg/dashboard_pause';
    import dashboard_close from '../icons/lg/dashboard_close';
    import dashboard_happy from '../icons/dashboard_happy';
    import dashboard_unhappy from '../icons/dashboard_unhappy';
    export default {
        components: {
          statusIcon, dashboard_check, dashboard_progress, dashboard_pause, dashboard_close, dashboard_happy, dashboard_unhappy
        },
        props: {
            project: Object,
            projectId: [Number, String],
            instance: Object,
            projectStatus: {
                type: String,
                default: 'active'
            },
            projectHealthStatus: {
                type: String,
                default: 'active'
            }
        },
        computed: {
          getWorkspace() {
            return this.instance.workspace.workspace;
          },
          getDefinition() {
            return this.getWorkspace?.definition_group;
          },
          getDefinitionItems() {
            let definitionGroup = this.getDefinition;
            return definitionGroup ? definitionGroup.items : [];
          },
          isFullScreen(){
            return this.instance.isFullScreen;
          }
        },
        methods: {
          getProject() {
            return this.instance.projects.find(item => item.id == this.project.id);
          },
          getProjectProgress(definitionItem) {
            let project = this.getProject();
            if(project) {
              return this.instance.summary.getProgress(project, definitionItem);
            }
            return 0;
          },
          getProjectIndicationProgress() {
            let sum = 0;
            this.getDefinitionItems.forEach((definitionItem, index) => {
              if(definitionItem.type === 2 || definitionItem.type === 3) {
                sum += this.getProjectProgress(definitionItem);
              }
            });
            return sum / 2;
          },
          getIndicationProgressColor(definitionItem) {
            let project = this.getProject();
            if(project) {
              let average = this.getProjectIndicationProgress();
              return this.instance.summary.getProgressColor(project, definitionItem, average / 25.01);
            }
            return 'Not found';
          },
          getIndicationProgressDescription(definitionItem) {
            let project = this.getProject();
            if(project) {
              let average = this.getProjectIndicationProgress();
              return this.instance.summary.getProgressDefinitionDescription(project, definitionItem, average / 25.01);
            }
            return 'Not found';
          },
          toggleProgress(definitionItem) {
            let project = this.getProject();
            if(project) {
              this.instance.summary.toggleProjectProgress(project, definitionItem);
            }
          },
          getProgressColor(definitionItem) {
            let project = this.getProject();
            if(project) {
              return this.instance.summary.getProgressColor(project, definitionItem);
            }
            return '';
          },
          getProgressDefinitionDescription(definitionItem) {
            let project = this.getProject();
            if(project) {
              return this.instance.summary.getProgressDefinitionDescription(project, definitionItem);
            }
            return 'Not found';
          },
          getProgressDefinitionTitle(definitionItem) {
            let project = this.getProject();
            if(project) {
              return this.instance.summary.getProgressDefinitionTitle(project, definitionItem);
            }
            return 'Not found';
          },
          getStatus(definitionItem) {
            let progress = this.getProjectProgress(definitionItem);
            return progress >= 100;
          },
          getProgressIcon(definitionItem) {
            let project = this.getProject();
            if(project) {
              return this.instance.summary.getProgressIcon(project, definitionItem);
            }
            return 'progress';
          },
        }
    }
</script>

<style scoped>
  .ProjectBoxIcon{
    width: 70px;
    height: 70px;
    display: grid;
    place-content: center;
  }
</style>
