<template>
    <div>
        <div class="flex gap-9 projectIntelligenceTop">
            <div class="w-455px flex-shrink-0">
                <ProjectBox
                    projectStatus="pause"
                    :project="selectedProject"
                    :instance="instance"
                    :progress="{type:'warning', value:'90%'}"
                />
            </div>

            <div class="w-955px h-full">
                <div class="h-240px py-18px px-30px">
                    <div class="flex w-full gap-10  top-right-filter">
                        <div class="w-1/2">
                            <NavContainer
                                :items="instance.progressView['main']"
                                @onClick="value => instance.progressView.switchComponent(value, 'main')"
                            />
                        </div>
                        <div class="w-1/2">
                            <div class="flex justify-start gap-2 fs-14 font-poppins text-primary-one fw-500">
                                <NavContainer
                                    :items="instance.progressView['groupOrAtomic']"
                                    @onClick="value => instance.progressView.switchComponent(value, 'groupOrAtomic')"
                                />
                                <NavContainer
                                    :items="instance.progressView['rangeOrField']"
                                    @onClick="value => instance.progressView.switchComponent(value, 'rangeOrField')"
                                />
                                <button @click="instance.progressView.toggleDefStyle()" class="py-1 px-2 dashboard_color_btn bg-primary-six text-white">Color</button>
                                <button class="py-1 px-2 pr-0" @click="instance.isFullScreen = !instance.isFullScreen">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.69521 17.3904H0L0.00869521 8.69521H2.48435V14.9061H8.69521V17.3904ZM17.3805 8.69521H14.9061V2.48435H8.69521V0H17.3904L17.3805 8.69521Z" fill="#646464"/>
                                    </svg>
                                </button>
                            </div>

                            <div class="bg-white shadow-two p-5 z-50 absolute right-20" v-if="instance.progressView.showDefStyle">
                                <DefinitionColorPicker
                                    :styles="instance.workspace.workspace.dashboard_style"
                                    @onColorChange="instance.summary.updateWorkspace({
                                        id: instance.workspace.workspace.id,
                                        name: instance.workspace.workspace.name,
                                        dashboard_style: instance.workspace.workspace.dashboard_style
                                    })"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="filterWrapper flex w-full">
                        <div class="leftFilterBoxWrapper relative">
                            <FilterBox
                                :instance="instance"
                                from="filterBoxLeft"
                                @changeModule="value => getFullModule(value, module => filterModuleLeft = module)"
                                @changeParameter="value => filterParameterLeft = value"
                                @changeDefinition="value => filterDefinitionLeft = value"
                            />
                        </div>
                        <div class="w-400px">
                            <FilterBox
                                :instance="instance"
                                from="filterBoxRight"
                                @changeModule="value => getFullModule(value, module => filterModuleRight = module)"
                                @changeParameter="value => filterParameterRight = value"
                                @changeDefinition="value => filterDefinitionRight = value"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex gap-9 projectIntelligenceBottom">
            <div class="w-455px h-652px flex-shrink-0 px-30px py-4">
                <ScenarioPanel :instance="instance" />
            </div>
            <div class="flex w-full py-5 h-652px">
                <div class="px-8 border-r border-secondary-five overflow-y-auto" :class="(instance.progressView.template != 'LineChart' && instance.progressView.template != 'Chart' ? 'w-1/2' : 'w-full')">
                    <div class="h-full">
                        <component
                            :module="filterModuleLeft"
                            :is="instance.progressView.template"
                            :filterParameter="filterParameterLeft"
                            :filterDefinition="filterDefinitionLeft"
                            :instance="instance"
                            :oppositeModule="filterModuleRight"
                            :oppositeFilterParameter="filterParameterRight"
                        ></component>
                    </div>
                </div>
                <div
                    v-if="(instance.progressView.template != 'LineChart' && instance.progressView.template != 'Chart')"
                    class="px-8 w-1/2 overflow-y-auto"
                >
                    <div class="h-full">
                        <component
                            :module="filterModuleRight"
                            :is="instance.progressView.template"
                            :filterParameter="filterParameterRight"
                            :filterDefinition="filterDefinitionRight"
                            :instance="instance"
                            :oppositeModule="filterModuleLeft"
                            :oppositeFilterParameter="filterParameterLeft"
                        ></component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue'
    import ProjectBox from "@/components/dashboard/projects/ProjectBox";
    import ScenarioPanel from "@/components/dashboard/ScenarioPanel"
    import NavContainer from "@/components/dashboard/progressView/components/NavContainer"
    import DefinitionColorPicker from "@/components/dashboard/progressView/DefinitionColorPicker"
    import FilterBox from '@/components/dashboard/progressView/FilterBox'

    const props = defineProps({
        instance: Object
    });

    const filterModuleLeft      = ref(Object(null))
    const filterParameterLeft   = ref(Object(null))
    const filterDefinitionLeft  = ref(Object(null))

    const filterModuleRight      = ref(Object(null))
    const filterParameterRight   = ref(Object(null))
    const filterDefinitionRight  = ref(Object(null))

    const selectedProject = computed(() => props.instance.selectedProject);

    const getFullModule = async (module, cb) => {
        let response =  await props.instance.progressView.getCollectionsByScenarioModule(props.instance.progressView.selectedScenario.id, module)
        cb(response);
    }
</script>

<script>
    import Chart from '@/components/dashboard/progressView/chart/Chart.vue'
    import Range from '@/components/dashboard/progressView/range/Range.vue'
    import Field from '@/components/dashboard/progressView/field/Field.vue'
    import LineChart from '@/components/dashboard/progressView/lineChart/LineChart.vue'

    export default {
        components: {
            Chart, Range, Field, LineChart
        }
    }
</script>


<style scoped>
    .dashboard_color_btn{
        background: #AD2B81;
        box-shadow: 0px 2.48435px 9.93739px rgba(44, 52, 65, 0.1);
        border-radius: 2px;
    }
    .leftFilterBoxWrapper{
        margin-right: 18px;
        padding-right: 21px;
        width: 446px;
    }
    .leftFilterBoxWrapper::before{
        content: '';
        position: absolute;
        left: 100%;
        bottom: 40px;
        border-right: 1px solid #c9c9c9;
        height: 56%;
    }
/*
    @media all and (max-width: 1850px)
    {
        .right-part .ws-heading-box {
            height: 55px;
        }
        #app ._main_wrapper{
            display: grid;
            grid-template-columns: 340px calc(100vw - 340px);
            overflow-x: hidden;
        }
        #app ._main_wrapper.no-left-panel-visible{
            grid-template-columns: 100vw;
        }

        #app .i-left-panel{
            width: 100%;
        }
        #app ._main_wrapper :is(.right-part, .i-right-panel){
            width: 100% !important;
        }

        #app ._main_wrapper :is(.right-part, .i-right-panel) ._content_wrapper{
            overflow-x: auto;
        }

        #app div[style*=transform-origin]{
            width: 100%;
            min-width: fit-content;
        }

        #app .i-menu{
            justify-content: flex-end;
        }
        #app .i-menu .text{
            width: 180px !important;
        }
    }
    @media all and (max-width: 1850px) and (max-width: 1601px){
        .projectIntelligenceTop {
            overflow-x: auto;
        }
    }
    @media all and (max-width: 1600px) and (min-width: 1401px){
        .projectIntelligenceTop{
            display: grid;
            margin-bottom: 20px;
            gap: 0;
            overflow-x: auto;
        }
        .projectIntelligenceTop>div>div{
            height: auto;
        }
        .filterWrapper>div{
            margin: 0;
        }
        .filterWrapper>div::before{
            display: none;
        }
        .leftFilterBoxWrapper{
            padding-right: 0;
        }
        .leftFilterBoxWrapper>div{
            margin-bottom: 0;
        }
        .top-right-filter>div>div {
            justify-content: center;
        }
        .projectIntelligenceTop>div{
            width: 100%;
        }
        .projectIntelligenceTop>div>div{
            justify-content: center;
        }

        .projectIntelligenceBottom {
            display: grid;
            gap: 20px;
        }
        .projectIntelligenceBottom>div{
            width: 100%;
            height: auto;
        }
    }
    @media all and (max-width: 1400px) and (min-width: 1300px){
        .filterWrapper{
            flex-direction: column;
            align-items: center;
        }
        .top-right-filter {
            gap: 15px;
            flex-direction: column;
            width: 100%;
            align-items: center;
        }
    } */
</style>
