<template>
    <div>
        <h2 class="fs-14 font-poppins capitalize text-primary-one fw-700">{{ title }}</h2>
        <div class="flex gap-2 items-center mt-2">
            <div class="progress_circle">
              <CircleProgress :size="40" :color="progress.color" :percent="percantage">
                <component :color="color" :is="getIconComponent"></component>
              </CircleProgress>

            </div>
            <h2 class="fs-30 fw-600 font-poppins text-primary-one">{{ percantage }}%</h2>
        </div>

        <h2 class="fs-14 font-poppins capitalize text-primary-one fw-600 mb-2 mt-5 truncate subtitle" :title="subtitle">{{ subtitle }}</h2>
        <div class="horizontal_progress_bar">
            <svg width="190" height="7" viewBox="0 0 190 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.0458984" width="100%" height="6" rx="2" fill="#E2E2E2" />
                <rect y="0.0458984" :width="percantage + '%'" height="6" rx="2" :fill="progress.color" />
            </svg>
        </div>
    </div>
</template>

<script>
    import overall_dashboard_check from '../icons/overall_dashboard_check';
    import overall_dashboard_progress from '../icons/overall_dashboard_progress';
    import overall_dashboard_pause from '../icons/overall_dashboard_pause';
    import overall_dashboard_close from '../icons/overall_dashboard_close';
    import overall_dashboard_happy from '../icons/overall_dashboard_happy';
    import overall_dashboard_unhappy from '../icons/overall_dashboard_unhappy';
    import CircleProgress from "./MiniCircleProgress.vue"
    export default {
        components: {
            CircleProgress,
            overall_dashboard_check,
            overall_dashboard_progress,
            overall_dashboard_pause,
            overall_dashboard_close,
            overall_dashboard_happy,
            overall_dashboard_unhappy
        },
        props: {
            title: String,
            percantage: [Number, String],
            emoji: String,
            subtitle: String,
            progress: Object,
            component: String,
            color: {
                String,
                default: '#DAA745'
            }
        },
        computed: {
          getIconComponent() {
            return `${this.component}`;
          }
        }
    }
</script>

<style scoped>
.subtitle {
  width: 220px;
}
.progress_circle {
  margin-left: -4px;
}
</style>
