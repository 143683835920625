<template>
    <div class="flex gap-5 items-center ellipseChartData" :class="{'mb-5': !instance.isFullScreen, 'mb-8': instance.isFullScreen}">
        <div class="relative">
            <CircleProgress :outerChart="outerChart" :color="chart.color" :percent="getChartValue" :solid-value="chart.value" />
        </div>
        <div>
            <h2 class="fs-14 fw-600 text-primary-one mb-1">{{ title }}</h2>
            <h2 class="fs-14 fw-500 text-color-616161">{{ subtitle }}</h2>
            <h2 class="fs-12 fw-500 text-secondary-one system-definition">
                <slot></slot>
            </h2>
        </div>
    </div>
</template>
<script>
import CircleProgress from "./CircleProgress"
export default {
    components: {
        CircleProgress
    },
    props: {
        instance: Object,
        title: {
            type: String
        },
        subtitle: {
            type: String
        },
        chart: {
            type: Object,
            default: function () { return {} }
        },
        outerChart: Object
    },
    computed: {
        getChartValue() {
            let value = this.chart && this.chart.value ? parseFloat(this.chart.value) : 0;
            return value * 10;
        }
    }
}
</script>

<style scoped>
.system-definition {
    width: 260px;
}
</style>
