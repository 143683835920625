<template>
  <div class="fs-14 font-poppins text-primary-one fw-600 mb-4">
        <span class="fs-14 font-poppins text-secondary-two fw-400">{{ label }}:</span>
        {{ value }}
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        value: [String, Number]
    }
}
</script>
