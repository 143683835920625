export default class ProgressSliceController {
    constructor(module) {
        this.module = module;
    }

    setModuleCollections(module) {
        this.module.collections = module.collections;
    }

    /*
        group => module.collections;
        atomic => module.last_levels;
    */ 

    getCollections(module, parameters) {
        if(parameters.length > 0 && parameters[1].active) {
            return module.last_levels;
        }
        return this.getGroupCollections(module);
    }

    getGroupCollections(module) {
        return module.collections;
    }

    getChildCollections(collections) {
        let lastLevels = [];
        collections.forEach(child => {
            lastLevels = this.getChild(child, lastLevels);
        });
        return lastLevels;
    }

    getChild(item, lastLevels) {
        if(item.child > 0) {
            item.child.forEach(child => {
                lastLevels = this.getChild(child, lastLevels);
            });
        } else {
            lastLevels.push(item);
            return lastLevels;
        }
    }
}
