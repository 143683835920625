<template>
<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_12015_280794)">
<circle cx="13" cy="11" r="8" fill="white"/>
<circle cx="13" cy="11" r="7.25" :stroke="color" stroke-width="1.5"/>
</g>
<path d="M13 11.8002C13.4418 11.8002 13.8 11.442 13.8 11.0002C13.8 10.5584 13.4418 10.2002 13 10.2002C12.5582 10.2002 12.2 10.5584 12.2 11.0002C12.2 11.442 12.5582 11.8002 13 11.8002Z" :fill="color"/>
<path d="M9.8 11.8002C10.2418 11.8002 10.6 11.442 10.6 11.0002C10.6 10.5584 10.2418 10.2002 9.8 10.2002C9.35817 10.2002 9 10.5584 9 11.0002C9 11.442 9.35817 11.8002 9.8 11.8002Z" :fill="color"/>
<path d="M16.2 11.8002C16.6419 11.8002 17 11.442 17 11.0002C17 10.5584 16.6419 10.2002 16.2 10.2002C15.7582 10.2002 15.4 10.5584 15.4 11.0002C15.4 11.442 15.7582 11.8002 16.2 11.8002Z" :fill="color"/>
<defs>
<filter id="filter0_d_12015_280794" x="0" y="0" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12015_280794"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12015_280794" result="shape"/>
</filter>
</defs>
</svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: '#2B80AD'
            }
        }
    }
</script>