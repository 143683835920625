<template>
  <div
    @click="$emit('onClick')"
    class="project_box border rounded p-5 cursor-pointer"
    :class="isSelected ? 'border-secondary-one' : 'border-secondary-four'"
  >
    <h2 class="font-poppins fs-14 fw-500 text-primary-one pb-2 mb-2 border-b border-secondary-two">
      {{ title }}
    </h2>
    <h2 class="font-poppins capitalize fs-12 fw-400 text-secondary-two mb-3">
      {{ subtitle }}
    </h2>
    <div class="flex gap-2 justify-between">
      <div class="font-poppins fs-12 fw-400 text-secondary-one">
        <h2 class="mb-2">Owner: {{ info.owner }}</h2>
        <h2 class="mb-2">Status: {{ info.status }}</h2>
        <h2 class="mb-2">Last edited: {{ info.edited }}</h2>
        <h2 class="mb-2">Creator: {{ info.creator }}</h2>
      </div>
      <img
        class="project-img block object-cover object-center rounded"
        :src="image"
        :alt="title"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
      },
      subtitle: {
        type: String,
      },
      image: {
        type: String,
      },
      info: {
        type: Object,
      },
      isSelected: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style scoped>
.project_box {
  width: 386px;
  /* height: 203px; */
  height: auto;
  box-shadow: 0px 2px 8px rgba(44, 52, 65, 0.1);
}
.project_box .project-img {
  width: 183px;
  height: 96px;
}
</style>
