<template>
    <div class="field_progress_bar">
        <h3 class="title font-poppins fs-18 fw-600 text-primary-one">{{title}}</h3>
        <div class="progress_bar relative h-1 bg-">
            <span class="_progress absolute h-full" :style="{left, width, background}">
                <span class="progress_text absolute font-poppins fs-14 fw-600 text-secondary-one">{{amount}}</span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            title: String,
            amount: String,
            left: String,
            width: String,
            background: {
              type: String,
              default: '#2B80AD'
            }
        }
    }
</script>
<style scoped>
  .field_progress_bar{
    padding-bottom: 49px;
    margin-bottom: 20px;
    border-bottom: 1px solid #EDEDED;
  }
  .title{
    margin-bottom: 35px;
  }
  .progress_bar{
    background-color: #D9D9D9;
    height: 8px;
    border-radius: 2px;
  }
  ._progress{
    border-radius: 2px;
  }
  .progress_text{
    top: -25px;
    /* transform: translateX(-50%); */
    left: 42%;
    white-space: nowrap;
  }
</style>
