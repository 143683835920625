<template>
  <div class="popup_content overflow-y-auto bg-white rounded-10px py-25px">
    <div class="header flex items-center justify-between px-30px pb-5 border-b border-secondary-five">
      <div class="left">
        <div class="flex gap-10 items-center">
          <button
            class="font-poppins cursor-pointer fs-20 fw-700 text-primary-one uppercase"
            :class="!IsProjectList && 'opacity-50'"
            @click="IsProjectList = true; $emit('changeComponent', 'Projects')"
          >
            PROJECTS IN DASHBOARD
          </button>
          <button
            class="font-poppins cursor-pointer fs-20 fw-700 text-primary-one uppercase"
            :class="IsProjectList && 'opacity-50'"
            @click="IsProjectList = false; $emit('changeComponent', 'Definition')"
          >
            DEFINITION SYSTEM
          </button>
        </div>
        <h2 class="font-poppins fs-16 fw-400 text-primary-one">
          Configure dashboard sentiments - this is a custom feature in dashboard
          not linked to system data
        </h2>
      </div>

      <div
        class="flex gap-6 items-center justify-end font-poppins fs-13 fw-500 text-primary-one"
      >
        <button
          v-if="!IsProjectList"
          @click="showModal=true"
          class="py-2 px-4 border border-secondary-five rounded"
        >
          Load Definition
        </button>

        <!-- close popup -->
        <svg
          @click="$emit('clickToCloseModal')"
          class="cursor-pointer"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
            fill="#3D3D3D"
          />
        </svg>
        <!-- /close popup -->
      </div>
    </div>
    
    <div class="overflow-y-auto h-770px">
      <slot></slot>
    </div>

    <Modal v-model="showModal">
      <div class="load_definition_wrapper p-6">
        <div class="head flex justify-between pb-2 border-b border-primary-one">
          <h2 class="font-poppins fs-20 fw-400 text-primary-one">Definition List</h2>
          <svg @click="showModal=false" data-v-22e31d76="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer"><path data-v-22e31d76="" d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#3D3D3D"></path></svg>
        </div>
        <div class="_body mt-2">
          <h2 
            v-for="(item, index) in this.definitionList" 
            :key="index" 
            @click="showModal=false; instance.definition.updateDefinition(item.id, instance);"
            class="cursor-pointer font-poppins fs-18 fw-500 text-primary-one p-2 rounded opacity-50 hover:opacity-100 border border-white hover:border-primary-one"
          >{{item.title}}</h2>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
import Modal from "@/elements/atom/Modal";
export default {
  components: {
    Modal,
  },
  props: {
    instance: {
      type: Object
    }
  },
  data() {
    return {
      IsProjectList: true,
      showModal: false,
      definitionList: []
    };
  },
  async mounted(){
    this.definitionList = await this.instance.definition.getAll();
  }
};
</script>

<style scoped>
  .popup_content {
    width: 1300px;
    height: 900px;
  }
  .load_definition_wrapper{
    width: 680px;
  }
  ._body{
    height: 547px;
    overflow-y: auto;
  }
</style>
