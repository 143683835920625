<template>
  <sunburst :data="localData" style="max-width: 700px; height: 100%" class="mx-auto">

    <!-- Add behaviors -->
    <template slot-scope="{ on, actions }">
      <highlightOnHover v-bind="{ on, actions }"/>
      <zoomOnClick v-bind="{ on, actions }"/>
      <popUpOnHover v-bind="{ on, actions }"/>
    </template>
    <!-- Add information to be displayed on top the graph -->
    <template v-slot:top="{ nodes }">
      <nodeInfoDisplayer
        :current="nodes.mouseOver"
        :root="nodes.root"
        style="font-size: 16px; "
        :showAllNumber="false"
        description=""
      />
    </template>

    <!-- Add bottom legend -->
    <breadcrumbTrail
      slot="legend"
      slot-scope="{ nodes, colorGetter, width }"
      :current="nodes.mouseOver"
      :root="nodes.root"
      :colorGetter="colorGetter"
      :from="nodes.clicked"
      :width="width"
      :item-width="100"
      :item-height="35"
      />
      <template slot="pop-up" slot-scope="{ data }">
        <div class="pop-up fs-14 text-primary-one">{{data.name}} </div>
      </template>

  </sunburst>
</template>

<script>
import {
  breadcrumbTrail,
  highlightOnHover,
  nodeInfoDisplayer,
  popUpOnHover,
  sunburst,
  zoomOnClick
} from 'vue-d3-sunburst';
import "vue-d3-sunburst/dist/vue-d3-sunburst.css";
import { getParameterValue } from '@/store/services/traits/parameter'
export default {
  components: {
    breadcrumbTrail,
    highlightOnHover,
    nodeInfoDisplayer,
    popUpOnHover,
    sunburst,
    zoomOnClick
  },
  props: {
    module: Object,
    filterParameter: Object,
  },
    computed: {
        localData() {
            return this.convert()
        }
    },
    methods: {
        convert() {
            let objString = JSON.stringify(this.module);
            let modifiedStr = objString.replaceAll(/title('|"):/gi, 'name$1:').replaceAll(/collections('|"):/gi, 'children$1:').replaceAll(/child('|"):/gi, 'children$1:');
            let obj = JSON.parse(modifiedStr);
            this.sizeInjector(obj);
            return obj;
        },
        sizeInjector(obj) {
            for(let item in obj) {
                if(item == 'children')
                {
                    if(obj.children.length > 0){
                        obj.children.forEach(this.sizeInjector)
                    }else{
                        obj.size = getParameterValue(obj, this.filterParameter);
                    }
                }
            }
        }
    }
}
</script>

<style>
.infornation-sunburst {
    font-size: 12px !important;
}
.infornation-sunburst > span {
 margin-top: 30px;
 display: inline-block;
}
.bread-sequence {
    font-size: 11px !important;
}
</style>
