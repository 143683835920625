<template>
  <div class="progress-circle__container">
      <span class="progress-circle__percent fs-20 fw-800"> {{ solidValue }} </span>

        <svg width="100" height="100" style="transform-origin: center; transform: rotateZ(90deg) scale(0.8)">
        <!--   bottom circle -->
          <circle 
            cx="50" 
            cy="50" 
            r="45" 
            fill="transparent" 
            stroke="#E6E6E6" 
            stroke-width="10"
          />
          
        <!--   colored circle -->
          <circle 
            cx="50" 
            cy="50" 
            r="45" 
            fill="transparent" 
            stroke="#ABA62B" 
            stroke-width="10"
            stroke-dasharray="283" 
            :stroke-dashoffset="`calc(283 - (283 * ${progressValue} ) / 100)`"
          />
          
        <!--     outer black circle -->
          <circle 
            v-show="outerChart.hasOuterCircle"
            cx="50" 
            cy="50" 
            r="48.8" 
            fill="transparent" 
            stroke="black"
            stroke-width="2.5"
            stroke-dasharray="306" 
            :stroke-dashoffset="`calc(306 - (306 * ${outerChart.value}) / 100 * 10)`"
          />
        </svg>
  </div>
</template>

<script>
export default {
    props: {
        color: String,
        percent: [String, Number],
        solidValue: [String, Number],
        outerChart: Object
    },
    computed: {
        progressValue() {
            return this.percent;
        },
    }
}
</script>

<style scoped>
.progress-circle {
  width: 50px;
  height: 50px;
  transform: scaleX(-1) rotate(127deg);
}
.progress-circle__percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.progress-circle__container {
  display: inline-block;
  position: relative;
}
.progress-circle__path {
  transition: 0.5s ease-in-out all;
}

</style>
