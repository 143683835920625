<template>
    <div class="basic_information_box">
        <div class="mb-8  border-b border-secondary-four">
            <h2 class="fs-14 font-poppins capitalize text-primary-one fw-700 mb-5">BASIC INFORMATION
            </h2>
            <DataList
                v-for="(value, key) in instance.collaborator.getWorkspace(instance.projects.length, instance.selectedProject)"
                :key="key"
                :label="key"
                :value="value"
            />
        </div>

        <div class="flex items-center justify-between mb-8">
            <h2 class="fs-14 font-poppins text-primary-one fw-700">PROJECT COLLABORATORS</h2>
            <svg class="cursor-pointer h-6 w-6" viewBox="0 0 24 24" fill="none"
                @click="gotoSettings()"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="11.5" stroke="#C9C9C9" />
                <path d="M17 12.7647H12.8235V17H11.1569V12.7647H7V11.2549H11.1569V7H12.8235V11.2549H17V12.7647Z" fill="#1C1C1D" />
            </svg>
        </div>
        <div class="members">
            <div v-for="member in instance.collaborator.getResponsibles(instance.project.project)" :key="member.id">
                <MemberBox
                    image="/images/profile.png"
                    :name="instance.collaborator.getMemberName(member)"
                    :designation="instance.collaborator.getRole(member.role)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import MemberBox from "./MemberBox";
    import DataList from "./DataList"
    export default {
        components: {
            MemberBox,
            DataList
        },
        props: {
            instance: Object
        },
        methods: {
          gotoSettings() {
            if(this.$route.params.projectId) {
              this.$router.push({ name: 'workspace_settings', params: { id: this.$route.params.workspaceId }, query: { type: 'project', id: this.$route.params.projectId, option: 'role' } });
            } else {
              this.$router.push({ name: 'workspace_settings', params: { id: this.$route.params.workspaceId } });
            }
          }
        }
    }
</script>
