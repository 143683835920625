<template>
<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_12015_280804)">
<circle cx="13" cy="11" r="8" fill="white"/>
<circle cx="13" cy="11" r="7.25" :stroke="color" stroke-width="1.5"/>
</g>
<path d="M15.4 8.59961L10.6 13.3996" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.4 13.3996L10.6 8.59961" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<filter id="filter0_d_12015_280804" x="0" y="0" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.129412 0 0 0 0 0.152941 0 0 0 0 0.192157 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12015_280804"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12015_280804" result="shape"/>
</filter>
</defs>
</svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: '#E93D3D'
            }
        }
    }
</script>