<template>
  <div class="pb-4 mb-4 mt-7">
        <div class="flex mt-3 mb-6px">
            <h1 class="fs-14 font-poppins fw-600 text-primary-four filter_tag"> Filter: </h1>
        </div>
        <div class="fs-18 font-poppins text-primary-one fw-600 selected_filter_items mb-3">
            <FilterNavigator
                filter="module"
                :from="from"
                :instance="instance"
                :items="instance.project.getModules()"
                @onChange="getProperties"
            />
        </div>
        <div class="flex gap-3 items-center justify-between mb-3">
            <FilterNavigator
                hasOpacity
                class="text-secondary-one fs-14 fw-400 filter_items w-190px flex-shrink-0"
                :instance="instance"
                :items="properties"
                @onChange="getParameters"
            />
            <FilterNavigator
                hasOpacity
                class="text-secondary-one fs-14 fw-400 filter_items w-190px flex-shrink-0"
                :instance="instance"
                :items="parameters"
                @onChange="value => $emit('changeParameter', value)"
            />
        </div>
        <div class="flex gap-3 items-center justify-between mb-3">
            <FilterNavigator
                hasOpacity
                class="text-secondary-one fs-14 fw-400 filter_items w-190px flex-shrink-0"
                :instance="instance"
                :items="instance.project.getDefinitions()"
                @onChange="value => $emit('changeDefinition', value)"
            />
            <FilterNavigator
                :instance="instance"
                :items="[{ title: 'Scenario Properties' }]"
                hasOpacity
                class="text-secondary-one fs-14 fw-400 filter_items w-190px flex-shrink-0"
            />
        </div>
    </div>
</template>

<script setup>
    import FilterNavigator from "./FilterNavigator";
    import { ref } from 'vue';
    const props =  defineProps({
        instance: Object,
        from: String
    });
    const emit = defineEmits(['changeModule']);
    const properties = ref([]);
    const parameters = ref([]);

    const getProperties = async (module) => {
        properties.value = await props.instance.project.getProperties(module.id); // have to follow for dashboard
        emit('changeModule', module);
    }

    const getParameters = (property) => {
        parameters.value = property ? property.property_collections : [];
    }    
</script>
