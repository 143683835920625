<template>
    <svg id="svg" width="200" height="200" viewPort="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle :r="r" cx="100" cy="100" fill="transparent" stroke-dasharray="565.48" stroke-dashoffset="0"></circle>
        <circle :stroke="color" :r="r" cx="100" cy="100" fill="transparent" :stroke-dasharray="strokeDashoffset" stroke-dashoffset="0"></circle>
    </svg>
</template>

<script>
    export default {
        props: {
            value: {
                type: Number,
                default: 10
            },
            color: {
                type: String,
                default: '#FF9F1E'
            }
        },
        data(){
            return {
                strokeDashoffset: 565.48,
                r: 90
            }
        },
        methods: {
            runCircle()
            {
                var c = Math.PI*(this.r*2);

                if (this.value < 0) { this.value = 0;}
                if (this.value > 100) { this.value = 100;}
                
                var pct = ((100-this.value)/100)*c;
                this.strokeDashoffset = pct;
            }
        },
        created()
        {
            this.runCircle()
        }
    }
</script>

<style scoped>
    #svg circle {
        stroke-dashoffset: 0;
        transition: stroke-dashoffset 1s linear;
        stroke: #666;
        stroke-width: 1em;
    }
</style>