<template>
  <button
        class="py-1 px-15px dashboard_tab_btn bg-white"
        v-text="label"
        :class="isActive&&'active'"
        @click="$emit('onClick')"
    ></button>
</template>

<script>
  export default {
      props: {
          label: String,
          isActive: Boolean
      }
  }
</script>
<style scoped>
  .dashboard_tab_btn{
    border: 1px solid #2B80AD;
    box-shadow: 0px 2px 8px rgba(44, 52, 65, 0.1);
    border-radius: 2px;
  }
  .dashboard_tab_btn.active{
    background-color: #2B80AD;
    color: #fff;
  }
</style>