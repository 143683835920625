<template>
  <div class="progress-circle__container">
        <span class="progress-circle__percent">
            <slot></slot>
        </span>
        <svg class="progress-circle" :width="size" :height="size" viewBox="0 0 140 140" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="ProgressBar">
                    <circle id="Oval" stroke="#E2E2E2" stroke-width="7" fill-rule="nonzero" cx="70" cy="70" r="50"></circle>
                    <path
                    class="progress-circle__path"
                    d="M70,120 C97.6142375,120 120,97.6142375 120,70 C120,42.3857625 97.6142375,20 70,20 C42.3857625,20 20,42.3857625 20,70 C20,97.6142375 42.3857625,120 70,120 Z" id="Oval-Copy"
                    :stroke="color"
                    stroke-width="7"
                    :stroke-dasharray="progressValue"
                    fill-rule="nonzero"
                    transform="translate(70.000000, 70.000000) rotate(-125.000000) translate(-70.000000, -70.000000)"
                    ></path>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        color: String,
        percent: [String, Number],
        size: Number,
        viewBox: {
            type: Object
        }
    },
    computed: {
        progressValue() {
            return ((this.percent / 100) * 100 * Math.PI) + ',9999';
        }
    }
}
</script>

<style scoped>
.progress-circle {
  transform: scaleX(-1) rotate(127deg);
}
.progress-circle__percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.progress-circle__container {
  display: inline-block;
  position: relative;
}
.progress-circle__path {
  transition: 0.5s ease-in-out all;
}
.progress-circle__button {
  text-decoration: none;
  margin-top: 24px;
  margin-left: 24px;
  background: #008080;
  color: white;
  padding: 18px 24px;
  border-radius: 4px;
  transition: 0.25s linear background;
}
.progress-circle__button:hover {
  cursor: pointer;
  background: #006767;
  transition: 0.25s linear background;
}
</style>