<template>
    <div>
        <h2 class="px-30px mt-4 font-poppins fs-14 fw-700 text-primary-one definition-title"
            contenteditable="true"
            placeholder="Definition title..."
            @blur="event=>instance.definition.updateTitle(event.target.innerText)"
            >{{definitionName}}</h2>
        <div class="grid grid-cols-3 gap-5 body px-30px pt-5">
            <div
                v-for="(item, i) in definitionItems"
                :key="item.id"
                class="border border-secondary-five rounded bg-primary-three p-5 pb-1 flex-shrink-0"
            >
                <div class="mb-5">
                    <h2
                        placeholder="Title..."
                        @input="(event)=>{
                            edited_items[i].title=event.target.innerText;
                            edited_items[i].definition.set_title = event.target.innerText;
                            submitToUpdate(edited_items[i]);
                        }"
                        v-once contenteditable="true"
                        class="font-poppins fs-14 fw-700 text-primary-one">
                        {{ item.title }}
                    </h2>
                    <h2
                        placeholder="Description..."
                        @input="(event)=>{
                            edited_items[i].description=event.target.innerText;
                            edited_items[i].definition.set_description = event.target.innerText;
                            submitToUpdate(edited_items[i]);
                        }"
                        v-once contenteditable="true"
                        class="font-poppins fs-14 fw-400 text-primary-one">
                        {{ item.description }}
                    </h2>
                </div>

                <DefinitionStatusBox
                    v-for="(range, index) in item.definition.range"
                    @getItemsData="(itemsData)=>{
                        edited_items[i].definition.title[index] = itemsData.title;
                        edited_items[i].definition.description[index] = itemsData.description;
                        submitToUpdate(edited_items[i]);
                    }"
                    :key="range+'_'+index"
                    :title="item.definition.title[index]"
                    :description="item.definition.description[index]"
                    :data="{ color: item.definition.color[index], text: item.definition.range[index] }"
                    :colors="item.definition.color"
                    :index="index"
                    :iconType="item.definition.icon[index]"
                    @onColorChange="() => {submitToUpdate(edited_items[i]);}"
                />
            </div>
        </div>
    </div>
</template>

<script>
import DefinitionStatusBox from "./DefinitionStatusBox";
    export default {
        components: { DefinitionStatusBox },
        props: {
            instance: Object
        },
        data(){
            return {
                edited_items: this.instance.definition.selectedDefinition.items,
                intarval: 1000,
                timeoutId: null
            }
        },
        computed:{
            definitionItems(){
                return this.instance.definition.selectedDefinition.items;
            },
            definitionName(){
                return this.instance.definition.selectedDefinition.name;
            },
        },
        methods: {
            submitToUpdate(data)
            {
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(()=>
                {
                    this.instance.definition.changeDefinitionData(data);
                }, this.intarval);

            },
            test(x){
                //
            }
        },
    }
</script>
