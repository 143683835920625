<template>
<svg :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_12015_280760)">
        <circle cx="13" cy="11" r="8" fill="white"/>
        <circle cx="13" cy="11" r="7.25" :stroke="color" stroke-width="1.5"/>
    </g>
    <path d="M16.6 8.2002L11.2667 13.8002L9 11.4002" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
        <filter id="filter0_d_12015_280760" x="0" y="0" :width="width" :height="height" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="2.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.129375 0 0 0 0 0.153105 0 0 0 0 0.191667 0 0 0 0.2 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12015_280760"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12015_280760" result="shape"/>
        </filter>
    </defs>
</svg>

</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: '#2BAD7E'
            },
            width: {
              type: [Number, String],
              default: 26
            },
            height: {
              type: [Number, String],
              default: 26
            }
        }
    }
</script>
