<template>
    <BaseLayout v-slot:="{ instance }">
        <div class="absolute dboard-menu">
            <div class="ml-5 flex gap-8 mb-9">
            <button v-for="template in templates"
                    :key="template.name"
                    @click="changeTemplate(template.name)"
                    class="cursor-pointer fs-16 fw-400 text-primary-one opacity-40"
                    :class="template.isActive&&'opacity-100'">{{ template.title }}</button>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="dashboard_wrapper">
                <component
                    :callBack=" () => { changeTemplate('ProjectIntelligence') }" 
                    :is="templateName"
                    :instance="instance"
                    sequence="first"
                    @openPopup="showModal = true"
                />
            </div>
        </div>
        <Modal v-model="showModal" v-if="instance">
            <Master :instance="instance" @clickToCloseModal="showModal=false" @changeComponent="(data)=>{modalComponentName=data}">
                <component :instance="instance" :is="modalComponentName"></component>
            </Master>
        </Modal>
    </BaseLayout>
</template>

<script>
import BaseLayout from "@/views/layouts/dashboard/BaseLayout.vue";
import CollaboratorPanel from "@/components/dashboard/collaborator/CollaboratorPanel";
import ProjectPanel from "@/components/dashboard/projects/ProjectPanel";
import ScenarioPanel from "@/components/dashboard/ScenarioPanel";
import SummaryPanel from "@/components/dashboard/summary/SummaryPanel";
import ProgressView from "@/components/dashboard/progressView/ProgressView";
import Modal from "@/elements/atom/Modal";
import Master from '@/components/dashboard/modalData/Master';
import Projects from '@/components/dashboard/modalData/Projects';
import Definition from '@/components/dashboard/modalData/Definition';
import ProjectSentiments from '@/components/dashboard/ProjectSentiments.vue'
import ProjectIntelligence from '@/components/dashboard/ProjectIntelligence.vue'

import dashboard_check from '@/components/dashboard/icons/lg/dashboard_check';
import dashboard_progress from '@/components/dashboard/icons/lg/dashboard_progress';
import dashboard_pause from '@/components/dashboard/icons/lg/dashboard_pause';
import dashboard_close from '@/components/dashboard/icons/lg/dashboard_close';

export default {
    components: {
        BaseLayout,
        SummaryPanel,
        ProjectPanel,
        ScenarioPanel,
        CollaboratorPanel,
        ProgressView,
        Modal,
        Projects,
        Definition,
        Master,
        ProjectSentiments,
        ProjectIntelligence,
        dashboard_check,
        dashboard_progress,
        dashboard_pause,
        dashboard_close
    },
    data() {
        return {
            showModal: false,
            contextPos: {
                top: 0,
                left: 0,
            },
            modalComponentName: 'Projects',
            templateName: 'ProjectSentiments',
            templates: [
                { name: 'ProjectSentiments', isActive: true, title: 'Project Sentiments' },
                { name: 'ProjectIntelligence', isActive: false, title: 'Project Intelligence' },
            ]
        };
    },
    methods: {
        changeTemplate(selectedTemplateName) {
            this.templates.forEach(template=>{
                this.templateName = selectedTemplateName
                if(template.name == selectedTemplateName){
                    template.isActive = true
                }else{
                    template.isActive = false
                }
            })
        }
    },
    async created() {
        if(this.$route.params.projectId) {
            this.$store.dispatch("project/getSolidObject", this.$route.params.projectId);
        }
    },
};
</script>


<style>
    @import "../../../assets/css/Dashboard/dashboard.css";
</style>
