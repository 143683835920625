<template>
  <div>
    <div class="relative top_part shadow-three">
        <GearIcon class="absolute w-5 h-5 gear_icon cursor-pointer" @click.native="$emit('openPopup')" />
        <div v-for="(definitionItem, index) in getDefinitionItems" :key="index">

          <template v-if="definitionItem.type === 1">
            <div class="flex gap-12 justify-between mr-4">

                <div class="content w-full">
                    <h2 class="fs-18 fw-800 text-primary-one capitalize">{{ definitionItem.title }}</h2>
                    <div class="flex gap-2 items-center justify-between">
                        <div class="fs-14 fw-500 text-primary-one">
                            <span class="fs-30 fw-700 mr-1">{{ getOverallSummary() }}%</span>
                            <!-- Actual -->
                        </div>
                        <!-- <div class="fs-14 fw-500 text-primary-one">
                            <span class="fs-30 fw-700 mr-1 text-primary-four">75%</span>
                            Target
                        </div> -->
                    </div>
                </div>

                <div class="circular_progress">
                  <CircleProgress :size="109" :color="getProgressColor(definitionItem)" :percent="getOverallSummary()">
                    <component
                        class="cursor-pointer"
                        :color="getProgressColor(definitionItem)"
                        :is="getProgressIcon(definitionItem).replaceAll(/-/ig, '_')">
                    </component>
                  </CircleProgress>
                </div>
            </div>

            <div class="-mt-4">
                <h2 class="fs-22 fw-800 text-primary-one">{{ getProgressDefinitionTitle(definitionItem) }}</h2>
                <p class="fs-18 fw-400 text-primary-one pr-10" :title="getProgressDefinitionDescription(definitionItem)">
                  {{ truncateString(getProgressDefinitionDescription(definitionItem), 77) }}
                </p>
            </div>
          </template>

          <template v-if="definitionItem.type === 2 || definitionItem.type === 3">
              <div>
                <h2 class="fs-18 fw-800 text-primary-one mb-2">{{ definitionItem.title }}</h2>
                <div class="flex gap-9">
                    <component class="-ml-2" :color="getProgressColor(definitionItem)" :is="getProgressIcon(definitionItem).replaceAll(/-/ig, '_')"></component>
                    <div>
                        <h2 class="fs-30 fw-700 text-primary-one">
                          {{ getAverageProjectProgressByDefinition(definitionItem) }}%
                          <!-- <span class="fs-14 fw-500 ">Actual</span> -->
                        </h2>
                        <svg width="193" height="6" viewBox="0 0 193 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="100%" height="6" fill="#E2E2E2"/>
                            <rect :width="getAverageProjectProgressByDefinition(definitionItem)+'%'" height="6" :fill="getProgressColor(definitionItem)"/>
                        </svg>
                    </div>
                </div>
                <div>
                    <h2 class="fs-22 fw-800 text-primary-one">{{ getProgressDefinitionTitle(definitionItem) }}</h2>
                    <p class="fs-18 fw-400 text-primary-one" :title="getProgressDefinitionDescription(definitionItem)">
                      {{ truncateString(getProgressDefinitionDescription(definitionItem), 80) }}
                    </p>
                </div>
              </div>
          </template>

        </div>
    </div>
    <hr class="hr my-5" />
    <ProjectPanel @onClick="callBack" :instance="instance" />
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canEdit, canRead, canUpdate, canDelete } = usePermission()
</script>

<script>
    import CircleProgress from "./CircleProgress";
    import circleChart from "./icons/circleChart";
    import overall_dashboard_check from './icons/overall_dashboard_check';
    import overall_dashboard_progress from './icons/overall_dashboard_progress';
    import overall_dashboard_pause from './icons/overall_dashboard_pause';
    import overall_dashboard_close from './icons/overall_dashboard_close';
    import overall_dashboard_happy from './icons/overall_dashboard_happy';
    import overall_dashboard_unhappy from './icons/overall_dashboard_unhappy';

    import dashboard_check from '@/components/dashboard/icons/lg/dashboard_check';
    import dashboard_progress from '@/components/dashboard/icons/lg/dashboard_progress';
    import dashboard_pause from '@/components/dashboard/icons/lg/dashboard_pause';
    import dashboard_close from '@/components/dashboard/icons/lg/dashboard_close';


    import ProjectPanel from "@/components/dashboard/projects/ProjectPanel";

  export default {
    data() {
        return {
            percent: 90,
            workspace: this.instance.workspace.workspace
        }
    },
    components: {
        CircleProgress,
        circleChart,
        overall_dashboard_check,
        overall_dashboard_progress,
        overall_dashboard_pause,
        overall_dashboard_close,
        overall_dashboard_happy,
        overall_dashboard_unhappy,
        ProjectPanel,
        dashboard_check,
        dashboard_progress,
        dashboard_pause,
        dashboard_close
    },
    props: {
        instance: Object,
        callBack: Function
    },
    computed: {
        circle() {
          return ((this.percent / 100) * 100 * Math.PI) + ',9999';
        },
        getWorkspace() {
          return this.instance.workspace.workspace;
        },
        getDefinition() {
          return this.getWorkspace?.definition_group;
        },
        getDefinitionItems() {
          let definitionGroup = this.getDefinition;
          return definitionGroup ? definitionGroup.items : [];
        }
      },
      methods: {
        truncateString(str, num) {
          if (str.length > num) {
            return str.slice(0, num) + "...";
          } else {
            return str;
          }
        },
        getAverageProjectProgressByDefinition(definitionItem) {
          return this.instance.summary.getAverageProjectProgressByDefinition(this.instance.getDisplayableProjects(), definitionItem);
        },
        getProgressColor(definitionItem) {
          let value = definitionItem.type !== 1 ? this.getAverageProjectProgressByDefinition(definitionItem) : this.getOverallSummary();
          return this.instance.summary.getAvgProgressColor(value, definitionItem);
        },

        getProgressDefinitionTitle(definitionItem) {
          let value = definitionItem.type !== 1 ? this.getAverageProjectProgressByDefinition(definitionItem) : this.getOverallSummary();
          return this.instance.summary.getAvgProgressDefinitionTitle(value, definitionItem);
        },

        getProgressDefinitionDescription(definitionItem) {
          let value = definitionItem.type !== 1 ? this.getAverageProjectProgressByDefinition(definitionItem) : this.getOverallSummary();
          return this.instance.summary.getAvgProgressDefinitionDescription(value, definitionItem);
        },
        getOverallSummary() {
          let summation = 0;
          this.getDefinitionItems.forEach(data => {
            if(data.type === 2 || data.type === 3) {
              summation += parseFloat(this.getAverageProjectProgressByDefinition(data));
            }
          });
          return summation > 0 ? (summation / 2).toFixed(2) :0;
        },
        getProgressIcon(definitionItem) {
          let value = definitionItem.type !== 1 ? this.getAverageProjectProgressByDefinition(definitionItem) : this.getOverallSummary();
          return 'overall_' + this.instance.summary.getAvgProgressIcon(value, definitionItem);
        },
        updateWorkspace() {
          let data = {
            id: this.workspace.id,
            name: this.workspace.name,
            personal_statement: this.workspace.personal_statement
          };
          this.instance.summary.updateWorkspace(data);
        }
      }
  }
</script>

<style scoped>
  .indication {
    height: 110px;
  }
  .overall-definition {
    height: 60px;
  }
  .hr{
    border-bottom: 1px solid #C9C9C9;
  }
/*   @media all and (max-width: 1850px) and (min-width: 1300px){
    .top_part{
      grid-template-columns: repeat(auto-fit, minmax(250px, 500px));
      height: auto;
      row-gap: 36px;
    }
  } */
</style>
