<template>
    <svg :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_15341_41544)">
            <circle cx="35" cy="33" r="25" fill="white"/>
            <circle cx="35" cy="33" r="24.0625" :stroke="color" stroke-width="1.875"/>
        </g>
        <path d="M30 25.5V40.5" :stroke="color" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M40 25.5V40.5" :stroke="color" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
            <filter id="filter0_d_15341_41544" x="0" y="0.5" width="70" height="70" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="2.5"/>
                <feGaussianBlur stdDeviation="5"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.172549 0 0 0 0 0.203922 0 0 0 0 0.254902 0 0 0 0.1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_15341_41544"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_15341_41544" result="shape"/>
            </filter>
        </defs>
    </svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: '#2BAD7E'
            },
            width: {
              type: [Number, String],
              default: 70
            },
            height: {
              type: [Number, String],
              default: 71
            }
        }
    }
</script>
