<template>
    <div class="flex gap-4 items-center justify-between">
        <ChevronLeftIcon @click.native="decrement" class="w-3 h-3 cursor-pointer" :class="{'opacity-40' : hasOpacity}" />
        <span class="mx-6px inline-block truncate w-full">{{ label }}</span>
        <ChevronRightIcon @click.native="increment" class="w-3 h-3 cursor-pointer" :class="{'opacity-40' : hasOpacity}" />
    </div>
</template>

<script setup>
    import { ref, watch, computed, inject } from 'vue';
    const emit = defineEmits(['onChange']);
    const props = defineProps({
        hasOpacity: Boolean,
        items: Array,
        instance: Object,
        filter: String,
        from: String
    });
    const currentIndex = ref(0);
   
    const label = computed(() => {
        let item = props.items && props.items[props.from == 'filterBoxLeft' ? props.instance.progressView.moduleIndex : currentIndex.value] || null;
        if(item) {
            emit('onChange', item);
            return item.title;
        }
        return 'Item not found';
    })
    const increment = () => {
        if(currentIndex.value < props.items.length - 1) {
            currentIndex.value++;
        }else {
            currentIndex.value = 0;
        }
        _syncModuleIndex(currentIndex)
    }
    const decrement = () => {
        if(currentIndex.value == 0) {
            currentIndex.value = props.items.length - 1;
        }else {
            currentIndex.value--;
        }
        _syncModuleIndex(currentIndex)
    }
    const _syncModuleIndex = (currentIndex) => {
        if(props.filter && props.filter == 'module' && props.from == 'filterBoxLeft') {
            props.instance.progressView.moduleIndex = currentIndex.value;
        }
    }
</script>
