var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"px-30px mt-4 font-poppins fs-14 fw-700 text-primary-one definition-title",attrs:{"contenteditable":"true","placeholder":"Definition title..."},on:{"blur":event=>_vm.instance.definition.updateTitle(event.target.innerText)}},[_vm._v(_vm._s(_vm.definitionName))]),_c('div',{staticClass:"grid grid-cols-3 gap-5 body px-30px pt-5"},_vm._l((_vm.definitionItems),function(item,i){return _c('div',{key:item.id,staticClass:"border border-secondary-five rounded bg-primary-three p-5 pb-1 flex-shrink-0"},[_c('div',{staticClass:"mb-5"},[_vm._o(_c('h2',{staticClass:"font-poppins fs-14 fw-700 text-primary-one",attrs:{"placeholder":"Title...","contenteditable":"true"},on:{"input":(event)=>{
                        _vm.edited_items[i].title=event.target.innerText;
                        _vm.edited_items[i].definition.set_title = event.target.innerText;
                        _vm.submitToUpdate(_vm.edited_items[i]);
                    }}},[_vm._v(" "+_vm._s(item.title)+" ")]),0,item.id),_vm._o(_c('h2',{staticClass:"font-poppins fs-14 fw-400 text-primary-one",attrs:{"placeholder":"Description...","contenteditable":"true"},on:{"input":(event)=>{
                        _vm.edited_items[i].description=event.target.innerText;
                        _vm.edited_items[i].definition.set_description = event.target.innerText;
                        _vm.submitToUpdate(_vm.edited_items[i]);
                    }}},[_vm._v(" "+_vm._s(item.description)+" ")]),1,item.id)]),_vm._l((item.definition.range),function(range,index){return _c('DefinitionStatusBox',{key:range+'_'+index,attrs:{"title":item.definition.title[index],"description":item.definition.description[index],"data":{ color: item.definition.color[index], text: item.definition.range[index] },"colors":item.definition.color,"index":index,"iconType":item.definition.icon[index]},on:{"getItemsData":(itemsData)=>{
                    _vm.edited_items[i].definition.title[index] = itemsData.title;
                    _vm.edited_items[i].definition.description[index] = itemsData.description;
                    _vm.submitToUpdate(_vm.edited_items[i]);
                },"onColorChange":() => {_vm.submitToUpdate(_vm.edited_items[i]);}}})})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }