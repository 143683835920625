<template>
  <div class="project_box_wrapper grid grid-cols-3 gap-7" :class="isFullScreen && 'w-full'">
        <div
            v-for="project in instance.getDisplayableProjects()"
            :key="project.id"
            class="project_box shadow-three"
            :class="[(projectId == project.id) && 'active']"
        >
            <ProjectBox
                projectStatus="pause"
                :project="project"
                :instance="instance"
                :projectId="projectId"
                @onClick="instance.switchProject(project, $route, $router); $emit('onClick')"
                :progress="{type:'warning', value:'90%'}"
            />
        </div>
    </div>
</template>

<script>
    import ProjectBox from "./ProjectBox";
    export default {
        components: {
            ProjectBox
        },
        props: {
            instance: Object
        },
        computed: {
            projectId() {
                return this.$route.params.projectId;
            },
            isFullScreen(){
                return this.instance.isFullScreen
            }
        },
        created() {
            this.instance.getProjects(this.$route);
        }
    }
</script>

<style scoped>
/* @media all and (max-width: 1850px)
{
    .right-part .ws-heading-box {
        height: 55px;
    }
    #app ._main_wrapper{
        display: grid;
        grid-template-columns: 340px calc(100vw - 340px);
        overflow-x: hidden;
    }
    #app ._main_wrapper.no-left-panel-visible{
        grid-template-columns: 100vw;
    }

    #app .i-left-panel{
        width: 100%;
    }
    #app ._main_wrapper :is(.right-part, .i-right-panel){
        width: 100% !important;
    }

    #app ._main_wrapper :is(.right-part, .i-right-panel) ._content_wrapper{
        overflow-x: auto;
    }

    #app div[style*=transform-origin]{
        width: 100%;
        min-width: fit-content;
    }

    #app .i-menu{
        justify-content: flex-end;
    }
    #app .i-menu .text{
        width: 180px !important;
    }


    .project_box_wrapper{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
    .project_box_wrapper>div{
        max-width: 600px;
        width: 100%;
    }
} */
</style>
