<template>
  <div class="overflow-y-auto h-full scenario_panel">
    <ProjectDetail
        v-for="scenario in  scenarios"
        :key="scenario.id"
        :scenario="scenario"
        description="A strategy based on high ambition innovation and thought leadership"
        @click.native="instance.progressView.setScenario(scenario)"
    >
        <ul class="modules_list fs-12 font-poppins text-primary-one fw-400 mb-4 mt-3">
            <li
                v-for="(module, index) in instance.project.getModules()"
                :key="module.id"
                class="my-4 cursor-pointer hover:opacity-75 fs-16 text-primary-one"
                :class="[index == instance.progressView.moduleIndex ? 'font-semibold' : 'font-normal']"
                @click="()=>{
                  instance.progressView.setModule(scenario, module, instance.project.getModules())
                  getModuleProperties(module)
                }"
            >
                {{ module.title }}
            </li>
        </ul>
    </ProjectDetail>
  </div>
</template>

<script setup>
    import ProjectDetail from "./ProjectDetail";
    import { computed, onMounted, ref, set } from 'vue';
    const props = defineProps({
        instance: Object,
    });
    const scenarios = ref([]);
    const isFullScreen = computed(() => props.instance.isFullScreen)
    const getModuleProperties = module => {
      props.instance.project.getProperties(module.id);
    }
    onMounted(() => {
        scenarios.value = props.instance.project.getScenarios();
        scenarios.value = scenarios.value.map((item, index) => {
            set(item, 'active', index == 0);
            return item;
        });
        if(Array.isArray(scenarios.value) && scenarios.value.length > 0) {
            props.instance.progressView.setScenario(scenarios.value.at(0));
        }
    });
</script>
