<template>
  <div class="py-5 border-t border-secondary-five">
    <div v-click-outside="hidePicker">
      <div @click="showColorPanel=!showColorPanel" class="flex gap-1 mb-3 -ml-1 cursor-pointer">
        <component :color="data.color" :is="iconType.replaceAll(/-/ig, '_')"></component>
        <span
          class="font-poppins fs-14 fw-700 text-dynamic-color"
          :style="'--color:' + data.color + ';'">{{ data.text }}</span>
        
      </div>
      <div v-if="showColorPanel && canEdit(project.cans)" class="absolute">
        <color-panel  v-model="colors[index]" ></color-panel>
      </div>
    </div>
    <h2 
      @input="(event)=>{itemsData.title=event.target.innerText; $emit('getItemsData', itemsData)}" 
      placeholder="Title..." 
      v-once 
      :contenteditable="canEdit(project.cans)" 
      class="font-poppins fs-14 fw-500 text-primary-one"
    >
      {{ title }}
    </h2>
    <h2 @input="(event)=>{itemsData.description=event.target.innerText; $emit('getItemsData', itemsData)}" placeholder="Description..." v-once :contenteditable="canEdit(project.cans)" class="font-poppins fs-14 fw-400 text-color-616161">{{ description }}</h2>
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canDelete, canCreate, canEdit } = usePermission()
</script>

<script>
  import dashboard_check from '../icons/dashboard_check';
  import dashboard_progress from '../icons/dashboard_progress';
  import dashboard_pause from '../icons/dashboard_pause';
  import dashboard_close from '../icons/dashboard_close';
  import dashboard_happy from '../icons/dashboard_happy';
  import dashboard_unhappy from '../icons/dashboard_unhappy';
  import {mapGetters} from 'vuex';

export default {
  components: { dashboard_check, dashboard_progress, dashboard_pause, dashboard_close, dashboard_happy, dashboard_unhappy },
  props: {
    colors: Array,
    index: Number,
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    data: {
      type: Object,
      default: function () {
        return {};
      }, //{color: #333, text: '26-50%'}
    },
    iconType: {
      type: String,
      default: "", // check | progress | pause | close
    },
  },
  data(){
    return {
      showColorPanel: false,
      itemsData: {
        title: this.title,
        description: this.description,
      }
    }
  },
  computed: {
    ...mapGetters({
      project: "project/project",
    }),
  },
  methods: {
    hidePicker() {
      if(this.showColorPanel) {
        this.showColorPanel = false
        this.$emit('onColorChange')
      }
    }
  }
};
</script>

<style scoped></style>
