<template>
  <div class="w-1/2">
      <div class="border-r border-secondary-four pr-5 slice_wrapper" :class="{'full-screen-slice_wrapper': instance.isFullScreen}">
        <FilterBox
            :instance="instance"
            @changeModule="value => filterModule = value"
            @changeParameter="value => filterParameter = value"
            @changeDefinition="value => filterDefinition = value"
        />
        <div class="overflow-y-auto" :class="{'data-wrapper': !instance.isFullScreen, 'full-screen-data-wrapper': instance.isFullScreen}">
            <component
                :module="localModule"
                :is="instance.progressView.template"
                :filterParameter="filterParameter"
                :filterDefinition="filterDefinition"
                :instance="instance"
            ></component>
        </div>
      </div>
    </div>
</template>

<script>
import Range from './range/Range';
import Field from './field/Field';
import Chart from './chart/Chart'
import FilterBox from "./FilterBox";

export default {
    components: {
        FilterBox,
        Range,
        Field,
        Chart
    },
    props: {
        instance: Object,
        sequence: String
    },
    provide() {
        return {
            'sequence' : this.sequence
        }
    },
    data() {
        return {
            filterModule: Object(null),
            filterParameter: Object(null),
            filterDefinition: Object(null)
        }
    },
    computed: {
        localModule() {
            if(this.sequence == 'first') {
                if(this.instance.progressView.from == 'scenario') {
                    return this.instance.progressView.module;
                }
                else {
                    return this.filterModule
                }
            }else {
                return this.filterModule;
            }
        }
        // componentName() {
        //     let { label } =  this.instance.progressView.source.rangeOrField.find(item => item.active);
        //     if(label == 'Ranges') {
        //         return 'Range'
        //     }else  {
        //         return 'Field'
        //     }
        // }
    }
}
</script>
<style>
.full-screen-slice_wrapper {
  height: 96% !important;
}
.data-wrapper {
  height: 290px;
  /*overflow-y: auto;*/
}
.full-screen-data-wrapper {
  height: 425px;
}
</style>
