<template>
    <div class="text-center">
        <img class="profile_img mb-1" :src="image" />
        <h2 class="fs-12 font-poppins text-primary-one fw-600">{{ name }}</h2>
        <h2 class="fs-10 font-poppins text-color-616161 fw-300 italic">{{ designation }}</h2>
    </div>
</template>

<script>
    export default {
        props: {
            image: {
                type: String
            },
            name: {
                type: String
            },
            designation: {
                type: String
            }
        }
    }
</script>
