<template>
    <div>
      <div v-show="shouldShow" class="flex gap-5 items-center ellipseChartData mb-5 pb-3 border-b border-secondary-five">
        <div class="relative">
          <div class="progress-circle__container">
            <span class="progress-circle__percent"> {{ getParameterValue(module, filterParameter.id, 'module_properties') }} </span>
            <svg width="100" height="100" style="transform-origin: center; transform: rotateZ(90deg);">
            <!--   bottom circle -->
              <circle
                      cx="50"
                      cy="50"
                      r="45"
                      fill="transparent"
                      stroke="#E6E6E6"
                      stroke-width="10"
                      />

            <!--   colored circle -->
              <circle
                      cx="50"
                      cy="50"
                      r="45"
                      fill="transparent"
                      :stroke="getDashboardBg(module, filterParameter.id, 'module_properties')"
                      stroke-width="10"
                      stroke-dasharray="283"
                      :stroke-dashoffset="`calc(283 - (283 * ${getParameterValue(module, filterParameter.id, 'module_properties')}) / 100 * 10)`"
                  />

            <!--     outer black circle -->
                <circle
                  v-show="hasOuterCircle"
                  cx="50"
                  cy="50"
                  r="49"
                  fill="transparent"
                  stroke="black"
                  stroke-width="2"
                  stroke-dasharray="305"
                  :stroke-dashoffset="`calc(305 - (305 * ${getParameterValue(oppositeModule, oppositeFilterParameter.id, 'module_properties')}) / 100 * 10)`"
                />
            </svg>
          </div>
        </div>
        <div>
          <h2 class="fs-16 fw-800 text-primary-one mb-1">{{ module.title }}</h2>
          <h2 class="fs-14 fw-600 text-black">{{ getDashboardDefinition(module, filterParameter.id, 'module_properties') }}</h2>
          <h2 class="fs-14 fw-500 text-secondary-one system-definition">
            {{ getSliderDefinitionByRange(module, filterParameter.id, 'module_properties') }}
          </h2>
        </div>
      </div>

      <EllipseChartData
          v-for="(collection, index) in localInstance.getCollections(module, instance.progressView.groupOrAtomic)"
          :key="index"
          :instance="instance"
          :title="`${collection.index_code} ${collection.title}`"
          :subtitle="getDashboardDefinition(collection, filterParameter.id)"
          :chart="{ color: getDashboardBg(collection, filterParameter.id), value: getParameterValue(collection, filterParameter.id) }"
          :outerChart="{hasOuterCircle, value: getParameterValue(localInstance.getCollections(oppositeModule, instance.progressView.groupOrAtomic)[index], oppositeFilterParameter.id)}"
        >
          {{ getSliderDefinitionByRange(collection, filterParameter.id) }}
        </EllipseChartData>
    </div>
</template>

<script>
  import EllipseChartData from "../EllipseChartData";
  import Definition from '@/store/services/traits/definition';
  import ProgressSliceController from "@/components/dashboard/progressView/progressSliceController";
  import {getDefinitionDescription, getParameterValue} from '@/store/services/traits/parameter';
  export default {
      components: {
          EllipseChartData
      },
      props: {
        module: Object,
        oppositeModule: Object,
        oppositeFilterParameter: Object,
        filterParameter: Object,
        filterDefinition: Object,
        instance: Object
      },
      data() {
          return {
              definition: new Definition,
              localInstance: null,
              shouldShow: false
          }
      },
      methods: {
          getParameterValueInPercentage(collection, parameterId) {
              let value = this.getParameterValue(collection, parameterId);
              return value * 10;
          },
          getParameterValue(collection, parameterId, key='properties') {
              return getParameterValue(collection, { id: parameterId }, key).toFixed(2);
          },
          getDashboardDefinition(collection, parameterId, key="properties") {
            return getDefinitionDescription(
                this.getParameterValue(collection, parameterId, key),
                this.instance.workspace.workspace.dashboard_style,
                'definition'
            );
          },
          getDashboardBg(collection, parameterId, key='properties') {
            return getDefinitionDescription(
                this.getParameterValue(collection, parameterId, key),
                this.instance.workspace.workspace.dashboard_style,
                'bg'
            );
          },
          getSliderDefinitionByRange(collection, parameterId, key='properties') {
            return this.definition.getDefinitionByRange(
                this.getSliderDefinition(),
                this.getParameterValue(collection, parameterId, key)
            );
          },
        getSliderDefinition() {
            let definitionId = this.filterDefinition.id;
            if(!definitionId) {
              return null
            }
            return this.definition.getSliderDefinition(definitionId, this.instance.project.project.definitions);
          },
      },
      created() {
        this.localInstance = new ProgressSliceController(this.module);
        this.shouldShow = true
      },
      computed: {
        hasOuterCircle(){
          let isDifferentFilter = this.oppositeFilterParameter.title != this.filterParameter.title
          let isSameModule  = this.module.title == this.oppositeModule.title
          return (isSameModule && isDifferentFilter)
        }
      }
  }
</script>


<style scoped>
  .progress-circle__percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    font-weight: 800;
  }
</style>
