<template>
    <div class="flex gap-10 flex-wrap body px-30px pt-5">
    <ProjectBox
        v-for="(project, index) in instance.projects"
        :key="index"
        :title="project.name"
        :subtitle="project.category"
        :image="(project?.file?.file_path) ? project.file.file_path : '/img/ws_demo_chart.61582aa9.png'"
        @onClick="instance.manageConfiguredProjects(project, $route)"
        :info="{
            owner: instance.project.getFullName(project.owner),
            status: (project.status === 1) ? 'Published' : 'Draft',
            edited: project.updated_at,
            creator: instance.project.getFullName(project.created_by),
        }"
        :isSelected="instance.configuredProjects.includes(project.id)"
    />
    </div>
</template>

<script>
    import ProjectBox from "./ProjectBox";
    export default {
    components: { ProjectBox },
        props: {
            instance: Object,
        },
        created() {
          // instance.switchProject(project, $route, $router)
            this.instance.getProjects(this.$route);
        }
    }
</script>

<style scoped>

</style>
