<template>
    <VueApexCharts  width="900px"  height="580px" :type="line" :options="options" :series="series" />
</template>

<script setup>
    import { ref } from 'vue'
    import VueApexCharts from 'vue-apexcharts'
    
    const chartBox = ref()
    const series = [{
        name: 'series-1',
        data: [3000, 2700, 2900, 2000, 2500, 1000]
    }]

    const options = {
        chart: {
          id: 'ChartId'
        },
        xaxis: {
          categories: ['Aut.', 'Sept.', 'Nov.', 'Dec.', 'Jan.', 'Feb.']
        },
        yaxis: {
          categories: [0, 1000, 2000, 3000]
        }
    }

</script>

<style lang="scss" scoped>

</style>