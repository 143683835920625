<template>
    <div @click="$emit('toggleProgress')">
        <svg v-if="status" class="Status_icon" width="30" height="31" viewBox="0 0 30 31" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_12298_888)">
                <circle cx="15" cy="13.0459" r="10" fill="white" />
                <circle cx="15" cy="13.0459" r="9.25" :stroke="color" stroke-width="1.5" />
            </g>
            <path d="M19.5 9.5459L12.8333 16.5459L10 13.5459" :stroke="color" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            <defs>
                <filter id="filter0_d_12298_888" x="0" y="0.0458984" width="30" height="30" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix"
                        values="0 0 0 0 0.129375 0 0 0 0 0.153105 0 0 0 0 0.191667 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12298_888" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12298_888" result="shape" />
                </filter>
            </defs>
        </svg>

        <svg v-else width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_12298_894)">
                <circle cx="15" cy="13.0459" r="10" fill="white" />
                <circle cx="15" cy="13.0459" r="9.25" :stroke="color" stroke-width="1.5" />
            </g>
            <path d="M13 10.0459V16.0459" :stroke="color" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            <path d="M17 10.0459V16.0459" :stroke="color" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            <defs>
                <filter id="filter0_d_12298_894" x="0" y="0.0458984" width="30" height="30" filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix"
                        values="0 0 0 0 0.129412 0 0 0 0 0.152941 0 0 0 0 0.192157 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12298_894" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12298_894" result="shape" />
                </filter>
            </defs>
        </svg>
    </div>

</template>

<script>
    export default {
        props: {
            status: {
                Boolean,
                default: false
            },
            color: {
              String,
              default: '#DAA745'
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
